/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { Decal, RenderTexture, Text, PerspectiveCamera, Html } from '@react-three/drei'
import * as THREE from 'three'

const material = new THREE.MeshPhongMaterial({
  color: 'red',
  flatShading: true
})

const TextMesh = () => {
  const [text, setText] = React.useState('')

  return (
    <>
      <Html
        fullscreen
        style={{
          padding: '2rem',
          color: 'white'
        }}>
        <input type="text" onChange={(e) => setText(e.target.value)} value={text} />
      </Html>
      <Text font="/HomemadeApple-Regular.ttf" fontSize={1.75} letterSpacing={-0.05} color="yellow">
        {text}
      </Text>
    </>
  )
}

export function Model(props) {
  const { nodes, materials } = useGLTF('/magicring.glb')
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Circle002.geometry} material={material} position={[1, 0.23, 0.13]} rotation={[0, 0, -1.57]} />
      <mesh castShadow receiveShadow geometry={nodes.Circle.geometry}>
        <meshStandardMaterial color="#b09040" metalness={1} roughness={0.2} />
        <Decal position={[0, 0.12, 0.665]} rotation={0} scale={[1.5, 0.15, 0.53]}>
          <meshStandardMaterial roughness={0} transparent polygonOffset polygonOffsetFactor={-10}>
            <RenderTexture attach="map" anisotropy={16}>
              <PerspectiveCamera makeDefault manual aspect={2.5 / 1} position={[0, 0, 5]} />
              <TextMesh />
            </RenderTexture>
          </meshStandardMaterial>
        </Decal>
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Circle001.geometry} material={material} position={[1.01, 0.15, 0]} rotation={[0, 0, -1.57]} />
      <mesh castShadow receiveShadow geometry={nodes.Circle003.geometry} material={material} position={[1.02, 0.06, 0.13]} rotation={[0, 0, -1.57]} />
    </group>
  )
}

useGLTF.preload('/magicring.glb')
