import { Canvas } from '@react-three/fiber'
import { Environment, SpotLight, Decal, RenderTexture, Text, AccumulativeShadows, RandomizedLight, PerspectiveCamera, OrbitControls, Html } from '@react-three/drei'
import { Model } from './ring'
import * as React from 'react'
import * as THREE from 'three'

function CameraHelper() {
  const camera = new THREE.PerspectiveCamera(75, 1, 1, 3)
  return (
    <group position={[4, 1.5, 0]} rotation={[0, 1.5, 0]}>
      <cameraHelper args={[camera]} />
    </group>
  )
}

export const App = () => {
  return (
    <Canvas shadows camera={{ position: [4, 1.5, 0], fov: 75 }}>
      {/* <CameraHelper /> */}
      <Model rotation={[0, 0, 0]} />
      {/* <Ring text="Abba" color="#b09040" position={[-0.5, 0.228, 0]} rotation={[Math.PI / 2, 0.228, -Math.PI / 4]} /> */}
      {/* <Ring text="Aima" color="#b09040" scale={0.92} position={[1, -0.02, 0]} rotation={[Math.PI / 2, 0, Math.PI / 4]} /> */}
      <AccumulativeShadows frames={100} color="red" alphaTest={0.95} colorBlend={1} opacity={1} scale={15} position={[0, -0.22, 0]}>
        <RandomizedLight amount={1} radius={5} ambient={0.8} intensity={1} position={[5, 5, 0]} bias={0.01} />
      </AccumulativeShadows>
      <SpotLight position={[0, 5, 0]} distance={5} angle={0.3} attenuation={5} castShadow penumbra={1} intensity={2} anglePower={7} />
      <directionalLight intensity={5} position={[3, 10, 0]} bias={0.01} />
      <OrbitControls enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.1} autoRotate autoRotateSpeed={0.8} />
      <Environment preset="apartment" />
    </Canvas>
  )
}
